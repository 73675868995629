<script>
import LegoModalLayout from '@/views/Helexium/Lego/LegoModalLayout.vue'
import AddBtn from '@/components/buttons/AddBtn.vue'

export default {
  components: {
    LegoModalLayout,
    AddBtn
  },
  async mounted() {
    this.personas = await this.$store.dispatch('getLegoPosiblesColaboradores', this.empresaId)
    this.legoRoles = await this.$store.dispatch('getLegoRoles')
    const titulo = document.getElementById('newBoardTitle')
    titulo.focus()
  },
  data() {
    return {
      legoRoles: [],
      personas: [],
      selectedColaborador: null,
      colaboradores: [],
      currencySection: 1,
      title: '',
      description: '',
      template: '',
      loading: false,
      selectedPlantilla: null
    }
  },
  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn
    },
    empresaId() {
      return this.$store.getters.userLoggedIn.empresa.id
    },
    posiblesColaboradores() {
      return this.personas.filter(persona => !this.colaboradores.find(colaborador => colaborador.personaId == persona.personaId))
    },
    roleDefault() {
      return this.legoRoles.sort((a, b) => b.level - a.level)[0].id
    },
    plantillas() {
      return this.$store.state.lego.boards.filter( b => b.isTemplate )
    }
  },
  methods: {

    nextStep() {
      const input = this.$el.querySelector('#newBoardTitle')
      if (input && !input.checkValidity()) {
        input.reportValidity()
        input.focus()
        return
      }

      this.currencySection++
    },
    prevStep() {
      this.currencySection--
    },
    async createBoard() {
      const input = this.$el.querySelector('#newBoardTitle')
      if (input && !input.checkValidity()) {
        input.reportValidity()
        input.focus()
        return
      }

      let collaborators = [...this.colaboradores]
      collaborators.unshift({
        personaId: this.userLoggedIn.id,
        name: this.userLoggedIn.nombres + ' ' + this.userLoggedIn.apellidos,
        roleId: this.legoRoles.find(rol => rol.level == 1).id,
        email: this.userLoggedIn.correo
      })

      const nuevoTablero = {
        title: this.title,
        description: this.description,
        collaborators,
        empresaId: this.empresaId,
      }

      this.loading = true
      if( this.selectedPlantilla ) {
        await this.$store.getters.fetchPost({ path: `lego/CreateBoardFromAPlantilla/${this.selectedPlantilla.id}/${this.userLoggedIn.id}/${this.title}`})
        await this.$store.dispatch('lego/getAndSetBoards')
      } else {
        await this.$store.dispatch('lego/crearTablero', nuevoTablero )
      }
      this.loading = false
      this.$router.push({ name: 'TusTableros'})

    },
    addColaborador() {
      if (this.selectedColaborador == null) return
      let colaborador = this.posiblesColaboradores.find(colaborador => colaborador.personaId == this.selectedColaborador.personaId)
      colaborador.roleId = this.roleDefault
      this.colaboradores.push(colaborador)
      this.selectedColaborador = null
    },
    deleteColaborador(index) {
      this.colaboradores.splice(index, 1)
    }
  },
  filters: {
    capitalize(value) {
      if (!value) return ''
      return value.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    }
  }
}
</script>

<template>
  <LegoModalLayout title="Nuevo tablero">
    <div class="body">
      
      <form v-show="currencySection == 1" @submit.prevent>
        <div class="mb-3">
          <label for="newBoardTitle">Titulo</label>
          <input @keyup.prevent.enter="nextStep" id="newBoardTitle" class="lego-input" type="text" v-model="title" required>
        </div>
        <div class="mb-3">
          <label for="">Inicializar con plantilla</label>
          <v-select class="lego" v-model="selectedPlantilla" :options="plantillas" :get-option-label="(option) => option.title"></v-select>
        </div>
        <div>
          <label for="newDescription">Descripción</label>
          <textarea class="lego-input" name="newDescription" id="newDescription" cols="30" rows="10"
            v-model="description" :disabled="selectedPlantilla != null"></textarea>
        </div>

      </form>


      <form v-show="currencySection == 2" @submit.prevent="addColaborador">
        <div>
          <label for="Colaboradores ">Colaboradores</label>
          <div class="inline-form">
            <v-select class="lego w-100" placeholder="Selecciona un colaborador" v-model="selectedColaborador"
              :get-option-label="(item) => item.name" :options="posiblesColaboradores">
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!selectedColaborador" v-bind="attributes" v-on="events" />
              </template>
            </v-select>
            <vs-button   class="m-0 button-add" type="submit">Agregar </vs-button>
          </div>
        </div>

        <div class="wrapper-colaboradores">
          <div class="colaborador" v-for="(colaborador, index) in colaboradores" :key="colaborador.personaId">
            <span>
              {{ colaborador.name | capitalize }}
            </span>
            <div class="controls-in-collaborator">
              <v-select class="lego" v-model="colaboradores[index].roleId" :reduce="item => item.id"
                :get-option-label="(item) => item.name" :options="legoRoles"></v-select>
              <span>
                <AddBtn class="close lego-danger" @click="deleteColaborador(index)" />
              </span>
            </div>
          </div>
        </div>
      </form>

      <footer class="d-flex justify-content-between align-content-center  controls">
        <div class="w-25">
          <vs-button @click="prevStep" v-show="currencySection == 2" danger  >Regresar</vs-button>
        </div>
  
        <div class="w-25 d-flex justify-content-end ">
          <vs-button v-show="currencySection == 1 && selectedPlantilla == null"   @click="nextStep">
            Siguiente
          </vs-button>
          <vs-button :loading="loading" v-show="currencySection == 2 || selectedPlantilla" success   @click="createBoard">
            Crear
          </vs-button>
        </div>
      </footer>
    </div>
  </LegoModalLayout>
</template>


<style scoped>
.body {
  min-height: 300px;
  display: flex;
  flex-direction: column;
}
.body form {
  flex: 1;
}

.colaborador {
  padding: .5rem;
  background-color: var(--lego-oscuro);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: .5rem 0;
}

.controls-in-collaborator {
  display: flex;
  gap: .5rem;
  align-items: center;
  width: 200px;
}

.controls-in-collaborator .lego {
  width: 100%;
}

</style>

